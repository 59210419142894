@import 'variables.scss';

body {
  margin: 0;
  padding: 0;
  background: white;
  position: relative;
  @media (max-width: 400px) {
    zoom: 85%;
  }
}

.page-container {
  margin-top: 100px;
}

//global styles to remove default browser styles
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  white-space: break-spaces;
}

//global styles for links
a {
  text-decoration: none;
  color: var(--black-white-black, #000);
}
li {
  list-style: none;
}
p {
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
strong {
  font-weight: 700;
}
.whatsapp-icon {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  img {
    width: 50px;
    height: 50px;
  }
}
.loader {
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.container-wrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  display: inherit; /* Inherit the display type, including flex */
  flex-grow: inherit; /* Inherit flex-grow from the parent */
  flex-shrink: inherit; /* Inherit flex-shrink from the parent */
  flex-basis: inherit; /* Inherit flex-basis from the parent */
  align-self: inherit; /* Inherit alignment (optional) */
  align-items: inherit;
  justify-content: inherit;
  flex-direction: inherit;
  gap: inherit;
}

@media (min-width: 576px) {
  .container-wrapper {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-wrapper {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-wrapper {
    max-width: 960px;
  }
}

@media (min-width: 1100px) {
  .container-wrapper {
    max-width: 1100px;
  }
}

@media (min-width: 1200px) {
  .container-wrapper {
    max-width: 1320px;
  }
}

@media (min-width: 1600px) {
  .container-wrapper {
    max-width: 1500px;
  }
}